<template>
  <div>
    <v-row>
      <!-- <v-col cols="12" md="12" lg="9">
        <v-card flat>
          <v-card-title>
            Reporte por Senior
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemsRSenior"
            :search="search"
          >
            <template slot="body.append">
              <tr class="pink--text">
                <th colspan="5" class="title">Totales</th>
                <th class="title">{{ sumField("cantidad") }}</th>
                <th class="title">{{ sumField("precio") }}</th>
                <th class="title">{{ sumField("subtotal") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col> -->

      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Lista de Senior con Reportes

            <vue-excel-xlsx
              class="ml-5"
              :data="itemsRSeniorS"
              :columns="columns"
              :filename="'Reporte por Senior'"
              :sheetname="'sheetname'"
              style="background: #c1c1c1; padding: 2px 10px; border-radius: 5px; font-size: 12px"
            >
              Descargar
            </vue-excel-xlsx>

            <vue-excel-xlsx
              class="ml-5"
              :data="itemsProvDetails"
              :columns="columnsDetails"
              :filename="'Reporte por Senior Detallado'"
              :sheetname="'sheetname'"
              style="background: #c1c1c1; padding: 2px 10px; border-radius: 5px; font-size: 12px"
            >
              Descargar con Detalles
            </vue-excel-xlsx>

            <v-autocomplete
              :items="itemsCampana"
              item-text="name"
              item-value="id"
              class="ml-5 mt-5"
              label="Seleccionar Campaña"
              v-model="id_campana"
              @change="loadRSeniorS()"
            >
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchS"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <!-- <table style="width:100%; text-align: left">
            <tr class="pink--text">
              <th colspan="1" class="title">Totales</th>

              <th colspan="2" class="title">
                {{ sumFieldSS("cantidad_t") }}
              </th>
              <th colspan="2" class="title">{{ sumFieldSS("subtotal") }}</th>
              <th colspan="1" class="title">{{ sumFieldSS("abono") }}</th>
              <th colspan="2" class="title">{{ sumFieldSS("resta") }}</th>
            </tr>
          </table> -->

          <v-data-table
            :headers="headersS"
            :items="itemsRSeniorS"
            :search="searchS"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2 mx-auto" @click="loadRSeniorSS(item.id)">
                mdi-eye
              </v-icon>
              <v-icon
                color="green"
                class="mr-2 mx-auto"
                @click="viewAbono(item.id, item.senior)"
              >
                mdi-account-cash
              </v-icon>
              <v-icon class="mr-2 mx-auto" @click="loadFacturado(item.id, item.fecha_corta, item.nro_factura, item.nombre_factura, item.documento_factura, item.direccion_factura)">
                mdi-cash-check
              </v-icon>
            </template>

            <template v-slot:item.descuento="{ item }">
              <v-btn @click="por(item.id, item.descuento)" elevation="0"
                >{{ item.descuento }}%</v-btn
              >
            </template>

            <template v-slot:item.prov="{ item }">
              <v-icon class="mr-2 mx-auto" @click="loadProv(item.id)">
                mdi-account-multiple
              </v-icon>
            </template>
            <template slot="body.append">
              <tr class="pink--text">
                <th colspan="2" class="title">Totales</th>

                <th colspan="2" class="title">
                  {{ sumFieldSS("cantidad_t") }}
                </th>
                <th colspan="1" class="title">{{ sumFieldSS("subtotal") }}</th>
                <th colspan="2" class="title">
                  {{ sumFieldSS("subtotal_senior") }}
                </th>
                <th colspan="1" class="title">{{ sumFieldSS("abono") }}</th>
                <th colspan="2" class="title">{{ sumFieldSS("resta") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2 style="color: #249c86">Ganancia: $ {{ valganancia }}</h2>
        <h2>Pagar a Proveedor: $ {{ sumFieldProv("compra_pro") }}</h2>
        <h2>Abonado a Proveedor: $ {{ sumFieldProv("abono") }}</h2>
        <h2>Saldo a Pagar: $ {{ sumFieldProv("restatwo") }}</h2>
      </v-col>
      <v-col cols="12" md="8">
        <h4>Costos Logísticos</h4>
        <v-simple-table fixed-header height="500px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Monto
                </th>
                <th class="text-left">
                  IGV
                </th>
                <th class="text-left">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in itemsCostos" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.monto }}</td>
                <td>{{ item.igv }}</td>
                <td>{{ item.total }}</td>
              </tr>
              <tr class="pink--text">
                <th colspan="1" class="title">Totales</th>

                <th colspan="1" class="title">$ {{ sumFieldCo("monto") }}</th>
                <th colspan="1" class="title">$ {{ sumFieldCo("igv") }}</th>
                <th colspan="1" class="title">$ {{ sumFieldCo("total") }}</th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogabono" width="1000">
      <v-card>
        <v-card-title>
          Abonos de {{ senior }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchProv"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="ab_fecha"
                type="date"
                label="Fecha"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="ab_monto" label="Monto"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="ab_banco" label="Banco"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="ab_transaccion"
                label="Nro. Transacción"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <croppa
                v-model="myCroppa"
                :width="400"
                :height="250"
                :placeholder-font-size="16"
                placeholder="Subir foto del articulo"
                :show-loading="true"
                :loading-size="50"
                :zoom-speed="10"
                loading-color="#606060"
              >
              </croppa>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="primary" @click="addAbono()" rounded>Agregar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-data-table
          :headers="headersAbono"
          :items="itemsAbono"
          :search="searchAbono"
        >
          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="1" class="title">Totales</th>
              <th class="title">{{ sumFieldAbono("monto") }}</th>
            </tr>
          </template>
          <template v-slot:item.deposito="{ item }">
            <router-link to=""
              ><img
                width="80"
                @click="verFoto(item.deposito)"
                :src="item.deposito"
                alt=""
            /></router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 mx-auto" @click="deleteAbono(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogabono = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogimg" width="1000">
      <v-card>
        <img :src="image_view" alt="" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_porcentaje" width="200">
      <v-card>
        <v-card-title>
          Descuento
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="se_por" label="Descuento"></v-text-field>
          <v-btn block @click="updateDesc()" color="primary">Guardar</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogfacturado" width="800">
      <v-card>
        <v-card-title>
          Fecha de Facturación
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="fecha_facturado" type="date" label="Fecha"></v-text-field>
          <v-text-field v-model="nro_factura" type="text" label="Nro. Factura"></v-text-field>
          <v-text-field v-model="documento_factura" type="text" label="RUC / DNI"></v-text-field>
          <v-text-field v-model="nombre_factura" type="text" label="Nombre"></v-text-field>
          <v-textarea v-model="direccion_factura" type="text" label="Direccion"></v-textarea>
          <v-btn block @click="updateFactura()" color="primary">Guardar</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogprov" width="1000">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchProv"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersProv"
          :items="itemsProv"
          :search="searchProv"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2 mx-auto"
              @click="loadRSeniorSSProv(item.id_proveedor, item.id_senior)"
            >
              mdi-eye
            </v-icon>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="1" class="title">Totales</th>
              <th class="title">{{ sumFieldPro("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogprov = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogprovs" width="1000">
      <v-card>
        <v-card-title>
          Lista de {{ nameProveedor }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchProvS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersProvS"
          :items="itemsProvS"
          :search="searchProvS"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2 mx-auto"
              @click="loadRSeniorSSProv(item.id_proveedor, item.id)"
            >
              mdi-eye
            </v-icon>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="7" class="title">Totales</th>
              <th class="title">{{ sumFieldProS("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogprovs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogrs" width="80%">
      <v-card>
        <v-card-title>
          Lista de {{ senior }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSS"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headersSS"
          :items="itemsRSeniorSS"
          :search="searchSS"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 mx-auto" @click="viewOrden(item.id)">
              mdi-eye
            </v-icon>
          </template>

          <template v-slot:item.cantidad_bulto="{ item }">
            <v-chip :color="getColor(item.porcentaje)" dark>
              {{ item.cantidad_bulto }}
            </v-chip>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th colspan="5" class="title">Totales</th>
              <th class="title">{{ sumFieldS("cantidad") }}</th>
              <th class="title">{{ sumFieldS("precio") }}</th>
              <th class="title">{{ sumFieldS("subtotal") }}</th>
            </tr>
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogrs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import VueExcelXlsx from "vue-excel-xlsx";
export default {
  name: "RSenior",
  data: () => ({
    myCroppa: {},
    search: "",
    searchS: "",
    searchSS: "",
    searchProv: "",
    searchProvS: "",
    searchAbono: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSS: [],
    itemsProv: [],
    itemsProvDetails: [],
    itemsProvS: [],
    itemsAbono: [],
    id: "",
    name: "",
    dialogrs: "",
    description: "",
    status: true,
    statusData: 1,
    itemsCampana: [],
    id_campana: "",
    dialogprov: false,
    dialogprovs: false,
    nameProveedor: "",
    dialogabono: false,
    dataUrl: "",
    dialogimg: false,
    image_view: "",
    se_por: "",
    se_id: "",
    itemsCostos: [],
    valganancia: "",
    dialogfacturado: false,
    fecha_facturado: '',
    itemsRProveedor: [],
    seniorId: '',
    nro_factura: '',
    documento_factura: '',
    nombre_factura: '',
    direccion_factura: '',
    dialog_porcentaje: false,

    ab_fecha: "",
    ab_monto: "",
    ab_banco: "",
    ab_transaccion: "",
    ab_image: "",
    ab_id_senior: "",

    headers: [
      {
        text: "Senior",
        align: "start",
        value: "senior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],

    headersS: [
      {
        text: "Senior",
        align: "start",
        value: "senior",
      },
      { text: "Descuento", value: "descuento" },
      { text: "Cantidad", value: "cantidad_t" },
      { text: "Precio Unit.", value: "precio" },
      { text: "SubTotal Junior", value: "subtotal" },
      { text: "SubTotal Senior", value: "subtotal_senior" },
      { text: "Proveedor", value: "prov" },
      { text: "Abono", value: "abono" },
      { text: "Resta", value: "resta" },
      { text: "Facturado", value: "fecha_corta" },
      { text: "Nombre Factura", value: "nombre_factura" },
      { text: "Acción", value: "actions" },
    ],

    headersAbono: [
      { text: "Fecha", value: "fecha_corta" },
      { text: "Monto", value: "monto" },
      { text: "Deposito", value: "deposito" },
      { text: "Banco", value: "banco" },
      { text: "Nro. Transacción", value: "nro_transaccion" },
      { text: "Acción", value: "actions" },
    ],

    headersProv: [
      {
        text: "Proveedor",
        align: "start",
        value: "proveedor",
      },
      { text: "SubTotal", value: "subtotal" },
      { text: "Acción", value: "actions" },
    ],

    columns: [
      {
        label: "Senior",
        field: "senior",
      },
      { label: "SubTotal", field: "subtotal" },
    ],

    columnsDetails: [
      { label: "Proveedor", field: "proveedor" },
      { label: "Senior", field: "senior" },
      { label: "Junior", field: "junior" },
      {
        label: "Catalogo",
        field: "catalogo",
      },
      { label: "Código", field: "codigo_producto" },
      { label: "Página", field: "pagina" },
      { label: "Producto", field: "producto" },
      { label: "Cantidad", field: "cantidad" },
      { label: "Precio de Venta", field: "precio" },
      { label: "Precio de Compra", field: "precio_compra" },
      { label: "Observación", field: "observacion" },
      { label: "SubTotal", field: "subtotal" },
    ],

    headersSS: [
      { text: "Junior", value: "junior" },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Cantidad x Bulto", value: "cantidad_bulto" },
      { text: "Cantidad Faltante", value: "falta" },
      { text: "Sub Total", value: "subtotal" },
    ],

    headersProvS: [
      { text: "Junior", value: "junior" },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],
  }),
  mounted() {
    this.loadCampanas();

    //this.loadRSeniorS();
  },
  methods: {
    loadRSenior() {
      axios.get(process.env.VUE_APP_API_URL + "rep/senior").then((result) => {
        this.itemsRSenior = result.data;
      });
    },
    loadAbonos(id) {
      axios.get(process.env.VUE_APP_API_URL + "abonos_filter/" + id + "/" + this.id_campana).then((result) => {
        this.itemsAbono = result.data;
      });
    },
    loadCostos() {
      axios
        .get(process.env.VUE_APP_API_URL + "campana/costos/" + this.id_campana)
        .then((result) => {
          this.itemsCostos = result.data;
          let val1 = this.sumFieldSS("subtotal_senior");
          let val2 = this.sumFieldCo("monto");
          this.valganancia = parseFloat(val1) - parseFloat(val2);
        });
    },
    verFoto(image) {
      this.image_view = image;
      this.dialogimg = true;
    },
    por(id, descuento) {
      this.se_id = id;
      this.se_por = descuento;
      this.dialog_porcentaje = true;
    },
    loadFacturado(id, fecha, nro, nombre, documento, direccion){
      this.dialogfacturado = !this.dialogfacturado;
      this.seniorId = id;
      this.fecha_facturado = fecha;
      this.nro_factura = nro;
      this.nombre_factura = nombre;
      this.documento_factura = documento;
      this.direccion_factura = direccion;
    },
    getColor(porcentaje) {
      if (porcentaje < 80) return "red";
      else if (porcentaje < 99.9) return "orange";
      else return "green";
    },
    viewAbono(id, senior) {
      this.ab_id_senior = id;
      this.dialogabono = true;
      this.senior = senior;
      this.loadAbonos(this.ab_id_senior);
    },
    loadCampanas() {
      axios.get(process.env.VUE_APP_API_URL + "campana_all").then((result) => {
        this.itemsCampana = result.data;
      });
    },

    loadRSeniorS() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "rep/campana/senior_with/" +
            this.id_campana
        )
        .then((result) => {
          this.itemsRSeniorS = result.data;
          this.loadProvDetails();
          this.loadRProveedor();
          this.loadCostos();
        });
    },

    loadRProveedor() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "campana/rproveedor/" + this.id_campana
        )
        .then((result) => {
          this.itemsRProveedor = result.data;
        });
    },

    sumFieldProv(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRProveedor.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    loadProv(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/campana/pro_se/" + id)
        .then((result) => {
          this.itemsProv = result.data;
          this.senior = result.data[0].senior;
          this.dialogprov = true;
        });
    },

    loadProvDetails() {
      axios
        .get(
          process.env.VUE_APP_API_URL + "rep/campana/details/" + this.id_campana
        )
        .then((result) => {
          this.itemsProvDetails = result.data;
        });
    },

    loadRSeniorSS(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/senior/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.senior = result.data[0].senior;
          this.itemsRSeniorSS = result.data;
        });
    },

    loadRSeniorSSProv(id, ids) {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/prov/senior/" + id + "/" + ids)
        .then((result) => {
          this.nameProveedor = result.data[0].proveedor;
          this.dialogprovs = true;
          this.itemsProvS = result.data;
        });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    addAbono() {
      this.dataUrl = this.myCroppa.generateDataUrl("image/jpeg", 0.8);
      let data = {
        id_campana: this.id_campana,
        id_senior: this.ab_id_senior,
        fecha: this.ab_fecha,
        monto: this.ab_monto,
        banco: this.ab_banco,
        nro_transaccion: this.ab_transaccion,
        deposito: this.dataUrl,
        status: 1,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "abonos", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Abono registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
            this.loadRSeniorS();
            this.loadAbonos(this.ab_id_senior);
            this.cleanAbono();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    updateDesc() {
      let data = {
        descuento: this.se_por,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "senior/desc/" + this.se_id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Porcentaje actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSeniorS();
            this.dialog_porcentaje = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    updateFactura() {
      
      let data = {
        facturado: 1,
        fecha_facturado: this.fecha_facturado,
        nro_factura: this.nro_factura,
        documento_factura: this.documento_factura,
        nombre_factura: this.nombre_factura,
        direccion_factura: this.direccion_factura,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "fechafacturado/" + this.seniorId + "/" + this.id_campana, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Factura actualizada con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSeniorS();
            this.dialogfacturado = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-EN")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldSS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-EN")
        .format(
          this.itemsRSeniorS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldPro(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsProv.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldCo(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-EN")
        .format(
          this.itemsCostos.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldAbono(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-EN")
        .format(
          this.itemsAbono.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldProS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-EN")
        .format(
          this.itemsProvS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el rubro no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "RSenior/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Rubro eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadRSenior();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El rubro no fue eliminado", "error");
        }
      });
    },

    deleteAbono(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el abono no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "abonos/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Abono eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadAbonos(this.ab_id_senior);
                this.loadRSenior();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El abono no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },

    cleanAbono() {
      this.ab_fecha = "";
      this.ab_monto = "";
      this.ab_transaccion = "";
      this.ab_banco = true;
    },
  },
};
</script>
